import { } from "@fortawesome/pro-solid-svg-icons";
import {
    faClipboardCheck,
    faCheckCircle,
    faFlagCheckered,
    faHandshake,
    faShieldCheck,
    faBoxCheck,
    faTools,
    faDraftingCompass,
    faCode,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Helmet } from "react-helmet";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";

import Layout from "../components/layout/Layout";
import PageHeader from "../components/PageHeader";

interface TimelineElementProps {
    icon: FontAwesomeIconProps["icon"],
    date?: string;
    title?: string;
    backgroundColor: React.CSSProperties["background"];
    textColor: React.CSSProperties["color"];
}

const TimelineElement = (props: React.PropsWithChildren<TimelineElementProps>) => {
    const { icon, children, date, title, backgroundColor, textColor } = props;
    return (
        <VerticalTimelineElement
            dateClassName="text-white lg:text-black"
            contentStyle={{ background: backgroundColor, color: textColor }}
            contentArrowStyle={{ borderRight: `7px solid ${backgroundColor}` }}
            date={date}
            iconStyle={{ background: backgroundColor, color: textColor }}
            icon={
                <div className="h-full w-full grid justify-center items-center">
                    <FontAwesomeIcon icon={icon} className="text-md lg:text-2xl" fixedWidth />
                </div>
            }>
            <div>
                {title && <h3 className="text-xl font-bold mb-2">{title}</h3>}
                {children}
            </div>
        </VerticalTimelineElement>
    );
};

const Page = () => {
    return (
        <Layout>
            <Helmet>
                <title>The Process</title>
                <meta name="description" content="A general guide to our development process."></meta>
            </Helmet>
            <PageHeader title="The Process">
            </PageHeader>
            <section className="pt-5 md:pt-15 container mx-auto px-8">
                <div className="mb-6">
                    <h2 className="text-xl text-primary-default">The Process</h2>
                    <p>
                        We understand that every project is different so we adjust our approach to tailor to the needs of you and your business.
                        Below is a representative flow showing how a project may run from start to end.
                    </p>
                </div>
                <VerticalTimeline>
                    <TimelineElement
                        date="Initial Contact"
                        title="Initial Consultation"
                        icon={faFlagCheckered}
                        backgroundColor="#4b9975"
                        textColor="#fff">
                        <p>
                            Once you have decided Eborsoft may have the right solution for you then reach out for a free of charge,
                            no commitment conversation about your requirements.
                        </p>
                    </TimelineElement>
                    <TimelineElement
                        date="Start of process"
                        title="Initial Requirements Gathering"
                        icon={faClipboardCheck}
                        backgroundColor="#0284C7"
                        textColor="#fff">
                        <p>
                            Once you decide you would like to work with us, at the very start of an engagement we will work to quickly
                            build enough knowledge of your business and requirements to enable us to create a full specification.
                        </p>
                        <p className="mt-6">
                            This process will likely involve interviews with stakeholders, high-level understanding of any current
                            systems in place and discussion of technology options. The goal is to build up a picture to allow us
                            to prepare the scope as efficiently as possible.
                        </p>
                    </TimelineElement>
                    <TimelineElement
                        date="Main planning phase"
                        title="Agree Scope and Timescales"
                        icon={faHandshake}
                        backgroundColor="#0284C7"
                        textColor="#fff">
                        <p>
                            With the initial requirements completed we will then distill this into a more formal scope document
                            and agree timescales. Some projects may be very straightforward in this regard, whereas others may
                            require some estimation of factors such as timescales. In every case we ensure that our thinking is
                            clear and transparent. 
                        </p>
                        <p className="mt-6">
                            It is at this point we will likely decide how you wish to be kept up to date of progress. This will
                            involve deciding when to touch base during the process as well as what tools might be used collectively
                            for project management. If required, Eborsoft can provide services using either Github or
                            Azure DevOps private repos.
                        </p>
                    </TimelineElement>
                    <TimelineElement
                        date="Plan what we're building"
                        title="Design"
                        icon={faDraftingCompass}
                        backgroundColor="#a76161"
                        textColor="#fff">
                        <p>
                            This is where the things start to take shape! Our team begins the work of high level code architecture
                            and UI design. At this stage we will be putting the fundamentals of the software together with final
                            storyboards and wire-frames where needed.
                        </p>
                        <p className="mt-6">
                            The delineation between this phase, and the next <span className="font-bold">implementation</span> phase can be somewhat fluid.
                            Sometimes for the architecture of software to properly take shape some of the initial foundations need to be
                            built early to help solidify ideas.
                        </p>
                    </TimelineElement>
                    <TimelineElement
                        date="This encompasses the majority of the project"
                        title="Implementation"
                        icon={faCode}
                        backgroundColor="#a76161"
                        textColor="#fff">
                        <p>
                            As you would expect, most of the time spent on the project will involve our team actively building your software.
                            We strive to keep the process as open as possible. We would expect to release multiple alpha and beta versions
                            of the software for you to try as we progress which allows for feedback and refinement. If we are hosting your software
                            for you on the cloud, we will get your test environment up and running at this stage.
                        </p>
                        <p className="mt-6">
                            We aim to get the initial research and scoping completed at the very start but it is sometimes unavoidable that last minute changes
                            to scope or specification may be required. Nothing beats playing with your new software early on and this can ignite new ideas or even cause
                            the direction to change somewhat. If this is the case - no problem! We are happy to return to scoping and work with you to ensure that
                            the final product meets your expectations and requirements.
                        </p>
                        <p className="mt-6">
                            If new ideas can't be implemented straight away there is always the potential to add them into the next release!
                        </p>
                    </TimelineElement>
                    <TimelineElement
                        title="Perform Security Testing"
                        icon={faShieldCheck}
                        backgroundColor="#7460a6"
                        textColor="#fff">
                        <p>
                            This is an optional step based on your requirements.
                            Once the first version of the software is complete we can provide
                            a secure development report showing how the development and testing of the software was performed
                            with respect to security. At additional cost we can also provide a certificate from a third
                            party CREST approved vendor, or we can work with a team of your choice.
                        </p>
                        <p className="mt-6">
                            If anything comes up from testing we move back to implementation and solve the issue.
                        </p>
                    </TimelineElement>
                    <TimelineElement
                        title="Product Delivery"
                        icon={faBoxCheck}
                        backgroundColor="#0284C7"
                        textColor="#fff">
                        <p>
                            This is the point at which we formally hand over your completed software to you. At this stage the
                            scope has been met and the software is ready to go.
                        </p>
                        <p className="mt-6">Items covered may include;</p>
                        <ul className="list-disc list-inside">
                            <li>Documentation and user guides.</li>
                            <li>Initial sign-in details.</li>
                            <li>First production App Store release (for mobile apps).</li>
                        </ul>
                    </TimelineElement>
                    <TimelineElement
                        title="Maintenance Handoff"
                        icon={faTools}
                        backgroundColor="#626262"
                        textColor="#fff">
                        <p>
                            Similar to the <span className="font-bold">Product Delivery</span> stage, this involves handing the software over
                            to your technical team. In some cases this may be an in-house development team or an external party. If you wish
                            Eborsoft to provide maintenance, we would perform this step internally.
                        </p>
                        <p className="mt-6">Items covered may include;</p>
                        <ul className="list-disc list-inside">
                            <li>Technical documentation.</li>
                            <li>At least one technical conversation.</li>
                            <li>Source code handover.</li>
                            <li>Hosting information.</li>
                        </ul>
                    </TimelineElement>
                    <TimelineElement
                        date="It's a wrap!"
                        title="Project Completion"
                        icon={faCheckCircle}
                        backgroundColor="#4b9975"
                        textColor="#fff">
                        <p>
                            All done! Whilst this project ends, we hope our relationship with you doesn't! Eborsoft strives to build long term, meaningful
                            relationships with all our customers. Once you own one of our software solutions we are as invested in it as you. We are always on
                            the end of a phone hand to offer advice and help, even if you decide to maintain the system yourself.
                        </p>
                        <p className="mt-6">
                            If a new project begins, or you decide you need to make changes to your new solution down the line, please don't hesitate to get in touch!
                        </p>
                    </TimelineElement>
                </VerticalTimeline>
            </section>
        </Layout>
    );
};

export default Page;
